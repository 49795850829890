var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "NewCategory" },
    [
      _c(
        "Drawer",
        {
          attrs: {
            title: (_vm.drawerType ? "编辑" : "新增") + "分类",
            size: "normal",
            "is-mask": false,
            visible: _vm.visible,
          },
          on: {
            close: function ($event) {
              _vm.visible = false
            },
            onSubmit: _vm.onSubmit,
          },
        },
        [
          _c(
            "a-form-model",
            {
              ref: "typeForm",
              attrs: {
                rules: _vm.rules,
                model: _vm.typeForm,
                "label-col": _vm.labelColType,
                "wrapper-col": _vm.wrapperColType,
              },
            },
            [
              _c(
                "a-form-model-item",
                { attrs: { label: "分类名称", prop: "name" } },
                [
                  _c("a-input", {
                    attrs: { placeholder: "请输入2-10个字符" },
                    model: {
                      value: _vm.typeForm.name,
                      callback: function ($$v) {
                        _vm.$set(_vm.typeForm, "name", $$v)
                      },
                      expression: "typeForm.name",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }